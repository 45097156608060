<template>
  <div>
    
    <div v-show="dialogVisible" id="dragBox" ref="dragBox" class="layerBox" @mousedown="move">
       
        <div style="width:500px;display:inline-block">
          <el-tabs ref="tabs" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane
              v-if="form.id"
              :label="'场站信息--' + form.layerName"
              name="one"
            ></el-tab-pane>
            <el-tab-pane v-else label="场站信息" name="one"></el-tab-pane>
            <el-tab-pane :disabled="!form.id" label="进出站管线" name="two"></el-tab-pane>
          </el-tabs>
        </div>
        <div class="caozu">
          <span class="colseBut" @click="colse"><i class="el-icon-circle-close"></i></span>
        </div>
        <div v-if="activeName == 'one'">
          <div v-if="!edit" class="Feature">
            <div>
              <el-form class="blueFormLabelClass" label-position="right" label-width="100px">
                <el-form-item label="所属企业名称:" class="formItemSmall">
                  <div class="equipment-item">
                    {{ form.companyName }}
                  </div>
                </el-form-item>
                <el-form-item label="企业场站编号:" class="formItemSmall">
                  <div class="equipment-item">
                    {{ form.stationCode }}
                  </div>
                </el-form-item>
                <el-form-item label="名称:" class="formItemSmall">
                  <div class="equipment-item">
                    {{ form.stationName }}
                  </div>
                </el-form-item>
                <el-form-item label="场站分类:" class="formItemSmall">
                  <div class="equipment-item">
                    {{ form.stationKindName }}
                  </div>
                </el-form-item>
                <el-form-item label="经纬度:" class="formItemSmall">
                  <div class="equipment-item">
                    {{ form.point }}
                  </div>
                </el-form-item>
                <el-form-item label="备注:" class="formItemSmall">
                  <div class="equipment-item">
                    {{ form.remark }}
                  </div>
                </el-form-item>
              </el-form>
            </div>
            <div v-if="!form.cz" class="footer">
              <el-button type="primary" class="but" size="mini" @click="editStation()">
                编 辑
              </el-button>
            </div>
          </div>
          <div v-else class="Feature">
            <el-form
              ref="form"
              :model="form"
              :rules="rules"
              label-position="right"
              label-width="100px"
            >
              <el-form-item label="所属企业名称:">
                <el-input
                  v-model="form.companyName"
                  class="selectWidth"
                  disabled
                  placeholder="请输入所属企业名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="企业场站编号:">
                <el-input
                  v-model="form.stationCode"
                  class="selectWidth"
                  maxlength="40"
                  placeholder="请输入企业场站编号"
                ></el-input>
              </el-form-item>
              <el-form-item label="名称:" prop="stationName">
                <el-input
                  v-model="form.stationName"
                  class="selectWidth"
                  maxlength="40"
                  placeholder="请输入名称"
                ></el-input>
              </el-form-item>
              <el-form-item label="场站分类:" prop="stationKind">
                <el-select
                  v-model="form.stationKind"
                  class="selectWidth"
                  popper-class="gis-default"
                  placeholder="请选择场站分类"
                >
                  <el-option
                    v-for="item in stationKindList"
                    :key="item.dictCode"
                    :label="item.name"
                    :value="item.dictCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="经纬度:">
                <el-input
                  v-model="form.point"
                  class="selectWidth"
                  disabled
                  placeholder="请输入经纬度"
                ></el-input>
              </el-form-item>
              <el-form-item label="备注:">
                <el-input
                  v-model="form.remark"
                  class="selectWidth"
                  type="textarea"
                  :rows="2"
                  maxlength="60"
                  placeholder="请输入备注"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="formbtn">
              <el-button size="mini" type="primary" @click="submit">保存</el-button>
              <el-button v-if="form.id" size="mini" @click="cancleEdit">取消</el-button>
            </div>
          </div>
        </div>
        <div v-if="activeName == 'two'">
          <div v-if="!form.cz" class="btnDiv">
            <el-button class="btn" size="mini" @click="stationPointAdd()">
              <i class="el-icon-plus"></i> 新增</el-button
            >
          </div>
          <el-table
            :data="tableData"
            :highlight-current-row="false"
            :header-cell-style="headerStyle"
            :row-style="headerStyle"
            :cell-style="headerStyle"
            style="width: 100%"
          >
            <el-table-column label="进出站" width="150" align="center">
              <template #default="{ row }">
                <div v-if="!row.addOrEdit">{{ row.channelType == 1 ? '进站' : '出站' }}</div>
                <div v-else>
                  <el-select
                    v-model="row.channelType"
                    popper-class="gis-default"
                    placeholder="请选择进出站"
                  >
                    <el-option
                      v-for="item in channelTypeList"
                      :key="item.dictCode"
                      :label="item.name"
                      :value="item.dictCode"
                    >
                    </el-option>
                  </el-select>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="名称" width="250" align="center">
              <template #default="{ row }">
                <div v-if="!row.addOrEdit">{{ row.pointName }}</div>
                <div v-else>
                  <el-input
                    v-model="row.pointName"
                    placeholder="请输入名称"
                    maxlength="40"
                  ></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="经纬度" width="350" align="center">
              <template #default="{ row ,$index}">
                <div v-if="!row.addOrEdit">
                  {{ row.pointArray[0][0] + ' ' + row.pointArray[0][1] }}
                  <el-tag class="selecttag" @click="setlineHight(row.point, $event)">高亮</el-tag>
                </div>
                <div v-else>
                  <el-input v-model="row.lnglat" disabled style="width:200px"></el-input>
                  <el-tag
                    v-if="row.point"
                    class="selecttag"
                    @click="setlineHight(row.point, $event)"
                    >高亮</el-tag
                  >
                  <el-tag class="selecttag" @click="getNodeIdByMap($index)">选取</el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column v-if="!form.cz" label="操作" width="100" align="center">
              <template #default="{ row,$index}">
                <div v-if="!row.addOrEdit">
                  <el-button type="text" class="btnText" @click="editStationPoint(row)"
                    >编辑</el-button
                  >
                  <el-button type="text" class="btnText danger" @click="deleteStationPoint(row)"
                    >删除</el-button
                  >
                </div>
                <div v-else>
                  <el-button type="text" class="btnText" @click="submitStationPoint(row)"
                    >保存</el-button
                  >
                  <el-button
                    type="text"
                    class="btnText danger"
                    @click="cancleStationPoint(row, $index)"
                    >取消</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getCompanyName, getCompanyId } from '../../utils/auth'
import { findDictTree } from '../../apis/commonType'
import {
  stationFindByIds,
  saveStation,
  stationUpdate,
  saveStationPoint,
  clearStationPoint,
  updateStationPoint
} from '../../apis/tool'
import { centerPointGetFourPoint, filterLayer, formatLocation } from '../../utils/geoTool.js'
import { serverMap, commonLayer, constantDict } from '../../utils/dict.js'
import { queryFeatrue } from '../../apis/geo'
export default {
  data() {
    return {
      dialogVisible: false,
      activeName: 'one',
      edit: true, //场站详情编辑
      form: {
        stationKind: ''
      },
      stationKindList: [],
      channelTypeList: serverMap['1012'],
      rules: {
        stationName: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        stationKind: [{ required: true, message: '请选择场站分类', trigger: 'change' }]
      },
      tableData: [],
      tableIndex: 0, //进出站地图选取index
      headerStyle: {
        color: '#fff',
        'background-color': '#244162',
        'border-right': 'rgba(110, 183, 227, 0.2) 1px solid',
        'border-bottom': 'rgba(110, 183, 227, 0.2) 1px solid'
      }
    }
  },
  mounted() {},
  methods: {
    getCodeName(type) {
      let code = type == 2 ? serverMap[1004][1].dictCode : serverMap[1004][0].dictCode
      findDictTree({ dictCode: code }).then(res => {
        if (res.data) {
          this.stationKindList = res.data[code]
          this.form.stationKind = this.stationKindList[0].dictCode
        }
      })
    },
    openDialog(obj) {
      this.activeName = 'one'
      if (obj.id) {
        this.form = obj
        delete this.form.pointId
        this.edit = false
        this.tableData = obj.channels
      } else {
        this.edit = true
        this.form.point = obj.point
        this.form.layerId = this.$store.getters.currentLayer
        this.form.companyName = getCompanyName()
        this.form.deptId = getCompanyId()
        this.form.pointId = obj.pointId
      }

      if (obj.equipmentType == 'userSta') {
        this.form.stationType = 2
        this.getCodeName(2)
      } else {
        this.form.stationType = 1

        this.getCodeName(1)
      }
      this.dialogVisible = true
      if (!obj.cz) {
        this.$parent.$refs.topTool.clearNoRemove()
      }
    },
    setstationKindName(value) {
      this.stationKindList.forEach(item => {
        if (item.dictCode == value) {
          this.form.stationKindName = item.name
        }
      })
    },
    // 查看页到编辑页
    editStation() {
      this.form.deptId = getCompanyId()
      this.edit = true
    },
    // 新增、更新场站信息
    submit() {
      let that = this
      that.$refs.form.validate(valid => {
        if (valid) {
          that.setstationKindName(that.form.stationKind)
          if (that.form.id) {
            // 修改场站信息
            stationUpdate(that.form)
              .then(res => {
                if (res.code == 200) {
                  that.$message.success(res.msg)
                  that.cancleEdit()
                } else {
                  that.$message.error(res.msg)
                }
              })
              .catch(err => {
                that.$message.error(err)
              })
          } else {
            // 新增场站信息
            saveStation(that.form)
              .then(res => {
                if (res.code == 200) {
                  that.$message.success(res.msg)
                  that.form.id = res.data
                  that.getList()
                  that.$eventBus.$emit('setFilter')
                  that.cancleEdit()
                } else {
                  that.$message.error(res.msg)
                }
              })
              .catch(err => {
                that.$message.error(err)
              })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 编辑页返回查看页
    cancleEdit() {
      this.edit = false
      this.getList()
    },
    // 获取场站详情进出点列表
    getList() {
      stationFindByIds({ id: this.form.id }).then(res => {
        let point = this.form.point
        this.form = res.data
        delete this.form.pointId
        this.form.point = point
        this.tableData = res.data.channels
      })
    },
    // 添加进出点
    stationPointAdd() {
      let obj = {
        addOrEdit: 'add',
        channelType: 1,
        pointName: '',
        pointId: '',
        lnglat: ''
      }
      this.tableData.push(obj)
    },
    // 地图上选取节点
    getNodeIdByMap(index) {
      this.$parent.$refs.topTool.clearNoRemove()
      this.$parent.isSelectNode = true
      this.tableIndex = index
    },
    // 进出点设为高亮
    setlineHight(pointStr, e) {
      let point = formatLocation(pointStr, 'Point')
      this.$parent.pointHighlight(point)
      //e.target.style.background = 'rgba(42, 130, 176 ,0.8)'
    },
    selectStationNode(lnglat) {
      let that = this
      let msg = '进出站只能吸附自由端普通节点上'
      that.getRangeFeature(lnglat, commonLayer['basePoint']).then(function(res) {
        let features = res.features
        let distances = { id: -1, dt: 0, index: 0 }
        if (features.length > 0) {
          features.forEach((item, index) => {
            if (
              item.properties.node_type2 == constantDict.jiedian.dictCode &&
              item.properties.is_free == 1
            ) {
              let point = item.properties.location_str
              let location = formatLocation(point, 'LineString')
              let distance = AMap.GeometryUtil.distanceToLine(lnglat, location)
              if (distances.dt == 0 || distances.dt > distance) {
                distances.id = item.properties.id
                distances.dt = distance
                distances.index = index
              }
            }
          })
          if (distances.id != -1) {
            let channel_type = features[distances.index].properties.channel_type
            if (channel_type == 1 || channel_type == 2) {
              that.$message.warning('该节点已被绑定，请重新选取')
            } else {
              let pointStr = features[distances.index].properties.location_str
              let point = formatLocation(pointStr, 'Point')
              that.$parent.pointHighlight(point)
              that.tableData[that.tableIndex].point = pointStr
              that.tableData[that.tableIndex].lnglat = point[0] + ' ' + point[1]
              that.tableData[that.tableIndex].pointId = distances.id
            }
          } else {
            that.$message.warning(msg)
          }
        } else {
          that.$message.warning(msg)
        }
      })
    },
    async getRangeFeature(lnglat, layerName) {
      let slq = filterLayer(this.$store)
      let filter = {}
      if (slq) {
        filter = { CQL_FILTER: slq }
      }
      let points = centerPointGetFourPoint(this.$parent.map, lnglat)
      let a = points.leftBottomPoint[0]
      let b = points.leftBottomPoint[1]
      let c = points.upRightPoint[0]
      let d = points.upRightPoint[1]
      let STYLES = ''
      let parames = {
        SERVICE: 'WMS',
        VERSION: '1.1.0',
        REQUEST: 'GetFeatureInfo',
        FORMAT: 'image/png',
        TRANSPARENT: true,
        QUERY_LAYERS: layerName,
        LAYERS: layerName,
        exceptions: 'application,Fvnd.ogc.se_inimage',
        INFO_FORMAT: 'application/json',
        FEATURE_COUNT: 50,
        X: 50,
        y: 50,
        SRS: 'EPSG:4326',
        WIDTH: 101,
        HEIGHT: 101,
        STYLES,
        BBOX: a + ',' + b + ',' + c + ',' + d,
        ...filter
      }
      return await queryFeatrue(parames)
    },

    submitStationPoint(row) {
      if (row.pointName == '') {
        this.$message.warning('名称不能为空')
      } else if (row.pointId == '') {
        this.$message.warning('经纬度不能为空')
      } else {
        if (row.addOrEdit == 'add') {
          row.id = this.form.id
          saveStationPoint(row)
            .then(res => {
              if (res.code == 200) {
                this.$message.success('保存成功')
                this.getList()
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch(err => {
              this.$message.error(err)
            })
        } else {
          updateStationPoint(row)
            .then(res => {
              if (res.code == 200) {
                this.$message.success('编辑成功')
                this.getList()
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch(err => {
              this.$message.error(err)
            })
        }
      }
    },
    cancleStationPoint(row, index) {
      if (row.addOrEdit == 'add') {
        this.tableData.splice(index, 1)
      } else {
        row.addOrEdit = ''
      }
    },
    editStationPoint(row) {
      this.$set(row, 'lnglat', row.pointArray[0][0] + ' ' + row.pointArray[0][1])
      this.$set(row, 'addOrEdit', 'edit')
    },
    // 删除进出点
    deleteStationPoint(row) {
      clearStationPoint({ pointId: row.pointId })
        .then(res => {
          if (res.code == 200) {
            this.$message.success('删除成功')
            this.getList()
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch(e => {
          this.$message.error(e.message)
        })
    },
    handleClick() {
      if (this.activeName == 'two' && !this.form.id) {
        this.$message.warning('请先保存场站信息')
      }
    },
    colse() {
      this.$parent.isSelectNode = false

      this.dialogVisible = false
      this.form = { stationKind: this.stationKindList[0].dictCode }

      this.$parent.$refs.topTool.clearTopTool()

      // this.form.stationKind = this.stationKindList[0].dictCode
    },
    move(e) {
      let odiv = this.$refs.dragBox
      let disX = e.clientX - odiv.offsetLeft
      let disY = e.clientY - odiv.offsetTop
      document.onmousemove = e => {
        let left = e.clientX - disX
        let top = e.clientY - disY
        this.positionX = top
        this.positionY = left

        odiv.style.left = left + 'px'
        odiv.style.top = top + 'px'
      }
      document.onmouseup = e => {
        document.onmousemove = null
        document.onmouseup = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title{
    height: 40px;
    line-height: 40px;
    background: linear-gradient(90deg, var(--background-title-skin1), var(--background-title-skin2));
}
::v-deep .el-tabs__item.is-active {
  color: var(--font-color-f1);
}
::v-deep .el-tabs__item {
  color: var(--font-color-c1);
}
::v-deep .el-tabs__active-bar {
  background-color: #6eb7e3;
}
::v-deep .el-tabs__nav-wrap::after {
  content: none;
}
::v-deep .el-tabs__header {
  margin: 0px;
}
::v-deep .el-button--text {
  background: transparent !important;
  border: none;
}
::v-deep .formItemSmall.el-form-item {
  margin-bottom: 2px;
}
.caozu {
  display: inline-flex;
  height: 40px;
  font-size: 14px;
  text-align: right;
  vertical-align: text-bottom;
  line-height: 36px;
  color: #a5b4c4;
  float: right;
}
.colseBut {
  font-size: 26px;
  vertical-align: middle;
  cursor: pointer;
}
.layerBox {
  position: absolute;
  top: 2.2rem;
  left: 5rem;
  min-height: 3rem;
  min-width: 3rem;
  border: 1px solid var(--border-color-skin);
  background: var(--background-color-skin);
  z-index: 2000;
  padding: 0px 20px 10px 20px;
}
.Feature {
 margin: 5px 5px 10px 0;
  line-height: 0.28rem;
  text-align: center;
  width: 100%;
  color: var(--font-color-f1);
}
.equipment-item {
  text-align: left;
  width: 380px;
  word-break: break-word;
}
.btnDiv {
  margin-bottom: 15px;
  .btn {
    margin-right: 15px;
  }
}
.formbtn {
  button {
    margin: 7px 15px 0 15px;
  }
}
.btnText {
  color: var(--font-color-f1);
  padding: 4px 5px;
}
/* table按钮颜色 */
.btnText.danger {
  color: #f74b4b;
}
.btnText.danger:hover,
.btnText.danger:focus {
  color: #f78989;
}
.selecttag {
  margin-left: 5px;
  cursor: pointer;
}
.selectWidth {
  width: 380px;
}
.title{
    height: 40px;
    line-height: 40px;
    background: linear-gradient(90deg, var(--background-title-skin1), var(--background-title-skin2));
}
</style>
